import React, { useState, useEffect } from 'react';


// - react-bootstrap
import {
  Container, Col, Row, Breadcrumb,
} from 'react-bootstrap';

// - Texto traduzido
import { Text } from 'containers/Language';

import {
  Banner,
  TitleBar,
  Overlay,
} from './styles';

const origin = process.env.REACT_APP_BASE_URL;

function PageHeader({
  bannerTitle, bannerSubtitle, pageTitle, breadcrumbs, img, page
}) {
  const [cover, setCover] = useState();

  useEffect(() => {
    fetch(`${origin}/api/covers/${page}`)
      .then(async (response) => {
        const json = await response.json();
        setCover(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });

  }, [img]);

  console.log({cover})

  if (!cover) {
    return null;
  }

  return (
    <>
      <Banner>
        <div className="banner-text">
          <h4 className="title text-white">
            {/* {bannerTitle} */}
            <Text content={bannerTitle} />
          </h4>
          {bannerSubtitle ? <h5 className="subtitle text-white">{bannerSubtitle}</h5> : ''}
        </div>
        <Overlay />
        {/* <img src={img || 'http://placehold.it/1920x800'} alt={`Banner ${pageTitle}`} className="img-responsive" /> */}
        <img src={`${origin}${cover?.imagePath}`} alt={`Banner ${pageTitle}`} className="img-responsive" />
      </Banner>
      <TitleBar>
        <Container>
          <Row>
            <Col>
              <h3 className="mb-0 mt-2">
                {/* {pageTitle} */}
                <Text content={pageTitle} />
              </h3>
            </Col>

            <Col className="d-flex justify-content-end">
              <Breadcrumb>
                {breadcrumbs.map((breadcrumb, index) => (index < breadcrumbs.length - 1 ? (
                  <Breadcrumb.Item key={index} href={breadcrumb.path}>{breadcrumb.title}</Breadcrumb.Item>
                ) : (
                  <Breadcrumb.Item key={index} active>{breadcrumb.title}</Breadcrumb.Item>
                )))}

                {/* <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
                <Breadcrumb.Item active>About Us</Breadcrumb.Item> */}
              </Breadcrumb>
            </Col>
          </Row>
        </Container>
      </TitleBar>

      <div className="clearfix" />
    </>
  );
}

export default PageHeader;
