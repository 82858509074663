/* eslint jsx-a11y/anchor-is-valid: 0 */
import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// - react-bootstrap
import { Container, Col, Row, Pagination } from 'react-bootstrap';

// - data
//import disciplines from 'assets/disciplines';

// - tradução
import { LanguageContext } from 'containers/Language';

// -- FontAwesome
import { faUser, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// - api
//import { portalApi } from 'services/api';

// - styled-components
import { Link } from 'react-router-dom';
import {
  TextBox,
  Title,
  PostInfo,
  Divider,
  FilterButton,
} from '../styles/newsListStyles';
//import news from 'assets/news';

//const imgBaseURL = 'http://www.canoagem.org.br/arquivos/noticias/destaque/';

//const origin = 'http://localhost:5500';
const origin = process.env.REACT_APP_BASE_URL;

function NewsList() {
  const { userLanguage } = useContext(LanguageContext);

  const [disciplines, setDisciplines] = useState([]);

  const [news, setNews] = useState([]);

  const [filter, setFilter] = useState('All');

  const [page, setPage] = useState(1);
  const perPage = 20;

  const [totalNews, setTotalNews] = useState(0);

  const totalPages = Math.ceil(totalNews / perPage);

  const tableRef = useRef(null);
  function executeScroll() {
    window.scrollTo({ behavior: 'smooth', top: tableRef.current.offsetTop });
  }

  const filteredNews = useMemo(
    () =>
      news.filter((news) =>
        filter === 'All' ? news : news.discipline === filter
      ),
    [news, filter]
  );

  console.log({filter})

  const location = useLocation();
  const { disciplineId } = location.state || {};
  //const disciplineRef = useRef(location.state || null);

  console.log({location, disciplineId})

  useEffect(() => {
    fetch(`${origin}/api/disciplines`)
      .then(async (response) => {
        const json = await response.json();
        setDisciplines(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });

    fetch(`${origin}/api/news?page=${page}&perPage=${perPage}`)
      .then(async (response) => {
        const json = await response.json();
        const totalCount = await response.headers.get('X-Total-Count');
        setTotalNews(Number(totalCount));
        setNews(json);
      })
      .catch((error) => {
        console.log('erro', error);
      });

    /* const queryParams = new URLSearchParams(location.search);
    const disciplineId = queryParams.get('disciplineId'); */

    if (disciplineId) {
      setFilter(disciplineId);
    } else {
      setFilter('All');
    }
  }, [page, location.state]);

  return (
    <Container ref={tableRef}>
      <Row style={{ paddingTop: 30 }}>
        <div className="labels">
          <FilterButton
            active={filter === 'All'}
            onClick={() => setFilter('All')}
          >
            All
          </FilterButton>
          {disciplines.map((discipline) => (
            <FilterButton
              key={discipline._id}
              active={filter === discipline._id}
              onClick={() => setFilter(discipline._id)}
            >
              {userLanguage === 'es'
                ? discipline.spanish.title
                : discipline.english.title}
            </FilterButton>
          ))}
        </div>
      </Row>
      <Row style={{ paddingBottom: 80 }}>
        {filteredNews.map(
          (newsItem, i) => {
            return (
              <Col
                sm={12}
                md={12}
                lg={6}
                style={{ marginTop: 50 }}
                key={newsItem._id}
              >
                <img
                  src={`${origin}${newsItem.imagePath}`}
                  alt=""
                  className="img-responsive"
                  style={{ height: '270px', width: '100%' }}
                />
                <Col md={12}>
                  <TextBox>
                    <Title>
                      <Link
                        /* to={`/news/${newsItem.id}/${newsItem.titulo.replace(/[^0-9A-Za-zÀ-ÖØ-öø-ÿ]/ig, '-').toLowerCase()}`} */
                        to={`/news/${newsItem._id}`}
                      >
                        {userLanguage === 'es'
                          ? newsItem.spanish.title
                          : newsItem.english.title}
                      </Link>
                    </Title>
                    <PostInfo>
                      <span>
                        <FontAwesomeIcon icon={faUser} />
                        {newsItem.author}
                      </span>
                      <span>
                        <FontAwesomeIcon icon={faCalendar} />
                        {`
                      ${new Date(newsItem.createdAt).toLocaleDateString(
                        userLanguage
                      )}

                      ${new Date(newsItem.createdAt).toLocaleTimeString(
                        userLanguage
                      )}
                    `}
                      </span>
                    </PostInfo>
                    <br />
                    <Divider />
                    <br />
                    <p>
                      {userLanguage === 'es'
                        ? newsItem.spanish.subheadline
                        : newsItem.english.subheadline}
                    </p>
                  </TextBox>
                </Col>
              </Col>
            );
          } /*: '') */
        )}
      </Row>
      <Row>
        <Col
          className="d-flex justify-content-center"
          style={{ padding: '20px 0 80px' }}
        >
          <Pagination>
            <Pagination.First
              onClick={() => {
                setPage(1);
                executeScroll();
              }}
            />
            <Pagination.Prev
              disabled={page === 1}
              onClick={() => {
                setPage(page - 1);
                executeScroll();
              }}
            />
            {[...Array(totalPages)].map((_, i) => (
              <Pagination.Item
                key={i}
                active={page === i + 1}
                onClick={() => {
                  setPage(i + 1);
                  executeScroll();
                }}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              disabled={page === totalPages}
              onClick={() => {
                setPage(page + 1);
                executeScroll();
              }}
            />
            <Pagination.Last
              onClick={() => {
                setPage(totalPages);
                executeScroll();
              }}
            />
          </Pagination>
        </Col>
      </Row>
      {/* <Row>
        <Col className="d-flex justify-content-center" style={{ padding: '20px 0 80px' }}>
          <Pagination>
            <Pagination.First onClick={() => { setPage(1); executeScroll(); }} />
            <Pagination.Prev disabled={page === 1} onClick={() => { setPage(page - 1); executeScroll(); }} />
            {[...Array(4)].map((_, i) => (
              <Pagination.Item active={page === (i + 1)} onClick={() => { setPage(i + 1); executeScroll(); }}>{i + 1}</Pagination.Item>
            ))}
            <Pagination.Next disabled={page === 4} onClick={() => { setPage(page + 1); executeScroll(); }} />
            <Pagination.Last onClick={() => { setPage(4); executeScroll(); }} />
          </Pagination>
        </Col>
      </Row> */}
    </Container>
  );
}

export default NewsList;
